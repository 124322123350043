<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <h1 class="text-xl text-left font-extrabold pl-4 mr-8">Overview</h1>
      <Breadcrumb :items="breadcrumbs" />
    </div>

    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <div v-if="!showError" class="mx-4 mt-3">
        <div class="w-full flex justify-between mt-2 gap-3">
          <Card
            class="text-white px-3 pt-6"
            style="background: #3454d1 !important; width: 38%"
          >
            <div class="flex">
              <Icon class-name="text-white " icon-name="union" size="l" />

              <div class="flex flex-col ml-4">
                <span class="font-thin">Next Pay Cycle</span>
                <span class="text-lg font-bold" v-if="payrunData.nextPaydate">
                  {{ setDate(payrunData.nextPaydate) }}</span
                >
                <span class="text-lg font-bold" v-else>N/A</span>
              </div>

              <div class="border bg-white w-0 mx-4" />

              <div class="flex flex-col">
                <span class="font-thin">Amount Due</span>
                <span class="text-lg font-bold">{{
                  convertToCurrency(payrunData.amountDue)
                }}</span>
              </div>
            </div>
          </Card>
          <Card
            class="text-white px-3 flex relative"
            style="
              background: #321c3b !important;
              box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06),
                0px 1px 3px rgba(8, 7, 8, 0.1);
              border-radius: 8px; width: 38%
            "
          >
            <div @mouseenter="isPayrunToolTip = true" @mouseleave="isPayrunToolTip = false"
              class="absolute top-2 right-2"
            >
              <Icon icon-name="information-line" class="cursor-pointer" size="xsm" />
              <ToolTip v-if="isPayrunToolTip" style="width: 250px !important" left="-119px">
                <template v-slot:header>
                  <p class="w-full mx-auto text-justify font-10 font-normal">
                    Backpay to access and process salary arrears.
                  </p>
                </template>
                <p class="w-full cursor-pointer font-10 text-blueCrayola flex justify-start items-center gap-2">
                  <span>Go to Off-Cycle Pay</span>
                  <Icon icon-name="right-arrow" class="text-blueCrayola right-arrow" size="s" />
                </p>
              </ToolTip>
            </div>

            <div class="flex">
              <Icon icon-name="wallet" class="" size="l" />
              <div class="flex flex-col ml-2 my-8">
                <span class="font-semibold text-xs uppercase"
                  >PAY RUNS (YTD)</span
                >
                <span class="font-bold text-lg"
                  >{{ payrunData.payrunCount }}
                </span>
              </div>
            </div>
            <div class="border bg-romanSilver w-0 ml-4 my-4" />
            <div class="flex">
              <div class="flex flex-col ml-4 my-8">
                <span class="font-semibold text-xs uppercase"
                  >total payroll cost (ytd)</span
                >
                <span class="font-bold text-lg">{{
                  convertToCurrency(payrunData.payrollCost)
                }}</span>
              </div>
            </div>
          </Card>
          <Card
            class="px-3 flex text-white"
            style="
             background: #13b56a !important;
              box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06),
                0px 1px 3px rgba(8, 7, 8, 0.1);
              border-radius: 8px; width: 28%
            "
          >
            <Icon icon-name="employee_payrun" class-name="ml-4" size="m" />

            <div class="flex flex-col my-8 ml-6">
              <span class="font-semibold uppercase text-xs"
                >Current no.of Employees</span
              >
              <span class="font-bold text-lg pt-2">{{
                payrunData.activePayrollEmployees
              }}</span>
            </div>
          </Card>
        </div>

        <div v-if="payrunData.payrunCount !== 0" class="w-full mt-6 gap-5">
          <Card class="flex flex-col w-full p-3">
            <h1 class="font-bold text-lg my-4">Pay Run Summary</h1>
            <div class="w-full flex justify-between">
              <span class="w-4/6 text-base"
                >This is an overview of your pay run summary throughout the
                months of the year.</span
              >

              <div class="">
                <c-select
                  placeholder="--Select--"
                  :options="overviewYears"
                  @onChange="handleYearsChange"
                  v-model="year"
                  label="Year to Date"
                  variant="w-full"
                />
              </div>
            </div>

            <sTable
              v-if="payrunData.payrunCount > 0"
              :headers="PayHeader"
              :items="payrunTableData"
              style="width: 100%"
              aria-label="payrun table"
              class="w-full mt-4"
              :has-number="false"
              :pagination-list="metaData"
              @page="handlePage($event)"
              page-sync
              @itemsPerPage="handleItemsPerPage($event)"
              :loading="loadingTable"
            >
              <template v-slot:item="{ item }">
                <span class="tw-font-extrabold" v-if="item.year">
                  {{
                    `${$DATEFORMAT(
                      new Date(item.data.year, item.data.month - 1, 1),
                      "LLLL"
                    )} ${item.data.year}`
                  }}
                </span>
                <span class="tw-font-extrabold" v-else-if="item.netEarnings">
                  {{ convertToCurrency(item.data.netEarnings) }}
                </span>
                <div v-else-if="item.payFrequency">
                  {{ handlePayFrequency(item.data.payFrequency) }}
                </div>
                <div class="" v-else-if="item.paySplitPosition">
                  {{ `${$getOrdinal(item.data.paySplitPosition)} Payment` }}
                </div>
                <span v-else-if="item.payDate">
                  {{
                    item.data.payDate
                      ? $DATEFORMAT(
                          new Date(item.data.payDate),
                          "MMMM dd, yyyy"
                        )
                      : "-"
                  }}
                </span>
                <span
                  :class="getColor(item.data.payrollStatus)"
                  v-else-if="item.payrollStatus"
                  class="
                    flex
                    p-2
                    capitalize
                    items-center
                    justify-center
                    ounded
                    tw-text-white
                  "
                >
                  {{ item.data.payrollStatus }}
                </span>
                <span v-else-if="item.payType" class="capitalize">
                  {{ item.data.payType }}
                </span>
                <!-- <span
                  v-else-if="item.id"
                  class="cursor-pointer"
                  @click="
                    $router.push({
                      name: 'View Payrun',
                      params: { id: item.data.id }
                    })
                  "
                >
                  <icon
                    icon-name="icon-arrow-right"
                    class="text-blueCrayola"
                    size="xms"
                  />
                </span> -->
              </template>
            </sTable>
          </Card>

          <Card class="flex w-full mt-3 p-3">
            <div class="w-1/2">
              <p class="font-bold text-lg">Pay Run Categories</p>
              <div class="flex justify-end my-6">
                <div class="flex gap-5 justify-center mt-4 items-center">
                  <Button
                    class="text-white -mt-2"
                    :background="appearance.buttonColor || defaultBTNColor"
                    @click="handlePayrunView"
                  >
                    Go to Pay Run
                  </Button>
                </div>
              </div>
              <div class="flex flex-col w-full mb-5 items-center">
                <DonutChart
                  :data="categoryObject"
                  :options="chartOptions"
                  :styles="myStyles"
                  :value="categoryTotal"
                />
              </div>
              <div class="w-full mt-3 grid grid-cols-12 pl-6 justify-center">
                <div
                  class="flex mb-3 col-span-4 p-1"
                  v-for="(legend, index) in categoryLegend"
                  :key="index"
                >
                  <div
                    class="rounded-full mr-2 mt-1"
                    :style="`width: 12px; height: 12px; background-color: ${legend.color}`"
                  />
                  <div class="text-sm text-romanSilver">
                    {{ legend.name }} : {{ legend.value }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="border border-dashed bg-black mx-4"
              style="width: 1px"
            />
            <div class="w-1/2">
              <p class="font-bold text-lg">Pay Run Status</p>
              <div class="flex justify-end my-6">
                <div class="flex gap-5 justify-center mt-4 items-center">
                  <Button
                    class="text-white -mt-2"
                    :background="appearance.buttonColor || defaultBTNColor"
                    @click="handlePayrunView"
                  >
                    Go to Pay Run
                  </Button>
                </div>
              </div>
              <div class="flex flex-col w-full mb-5 items-center">
                <DonutChart
                  :data="chartDataObject"
                  :options="chartOptions"
                  :styles="myStyles"
                  :value="payrunTotal"
                />
              </div>
              <div class="w-full mt-3 grid grid-cols-12 pl-6 justify-center">
                <div
                  class="flex mb-3 col-span-4 p-1"
                  v-for="(legend, index) in chartLegend"
                  :key="index"
                >
                  <div
                    class="rounded-full mr-2 mt-1"
                    :style="`width: 12px; height: 12px; background-color: ${legend.color}`"
                  />
                  <div class="text-sm text-romanSilver">
                    {{ legend.name }} : {{ legend.value }}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div v-else class="mt-12">
          <Card>
            <p class="text-gray-00 py-12 text-center">
              Pay runs will be listed here once your organization runs a pay
              run.
            </p>
          </Card>
        </div>
      </div>
      <div v-else>
        <error-page />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import DonutChart from "@/components/donutChart";
import Button from "@/components/Button";
import ToolTip from "@/components/ToolTip";
import ErrorPage from "@/modules/Admin/error403";

export default {
  name: "Function",
  components: {
    Card,
    STable,
    DonutChart,
    Breadcrumb,
    ErrorPage,
    Button,
    ToolTip,
    CSelect: () => import("@scelloo/cloudenly-ui/src/components/select"),
  },
  data() {
    return {
      perfArray: [],
      kpaSummary: {},
      labels: [],
      showError: false,
      earningData: [],
      defaultData: 100.0,
      loading: true,
      AppLabel: [],
      payrollCategory: {},
      categoryData: [],
      categoryObject: {},
      categoryLegend: [],
      categoryTotal: 0,
      metaData: {},
      itemsPerPage: 10,
      numberOfPage: 1,
      loadingTable: true,
      annualGrossPay: "",
      loadingData: false,
      payrunTableData: [],
      payrunData: {},
      chartDataObject: {},
      overviewYears: [],
      chartOptions: {
        legend: {
          display: false,
          position: "bottom",
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      chartLabels: [],
      chartData: [],
      chartLegend: [],
      payrunTotal: "",
      myStyles: {
        height: `300px`,
        width: `300px`,
        position: "relative",
      },
      year: new Date().getFullYear(),
      totalAmount: 0,
      PayHeader: [
        { title: "Pay cycle", value: "year" },
        { title: "Pay run Type", value: "payType" },
        { title: "Pay Frequency", value: "payFrequency" },
        { title: "Pay SCHEDULE", value: "paySplitPosition" },
        { title: "Amount Processed", value: "netEarnings" },
        { title: "Pay Date", value: "payDate" },
        { title: "Status", value: "payrollStatus" },
        // { title: "", value: "id", image: true }
      ],
      breadcrumbs: [
        {
          disabled: false,
          text: "Payroll",
          href: "Performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Overview",
          href: "Performance Agreements",
          id: "Agreement",
        },
      ],

      isPayrunToolTip: false
    };
  },
  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      this.loadingTable = true;
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${Number(this.metaData.page)}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      const { year } = this;
      this.getAllAnalytics(year, pageNumber, itemPage);
    },
    setDate(date) {
      const newDate = new Date(date);
      newDate.setHours(newDate.getHours() + 8);
      return this.$DATEFORMAT(new Date(`${newDate}`), "do, LLLL yyyy");
    },
    handlePayFrequency(value) {
      switch (value) {
        case "twice_monthly":
          return "Twice Monthly";
        case "monthly":
          return "Monthly";
        case "daily":
          return "Daily";
        case "weekly":
          return "Four Times Monthly";
        default:
          return "";
      }
    },
    async handlePayrunView() {
      try {
        await this.$handlePrivilege("overviewHR", "goToPayRun");
        this.$router.push({ name: 'Pay Runs' });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", { duration: 5000 });
      }
    },
    async viewDashboard() {
      try {
        await this.$handlePrivilege("overviewHR", "viewOverview");
        this.loading = false;
        this.showError = false;
        this.queryUpdate();
      } catch (error) {
        this.showError = true;
        this.loading = false;
      }
    },
    getColor(value) {
      if (value === "paid") {
        return "account_active";
      }
      if (value === "review" || value === "approved") {
        return "account_pending";
      }

      return "account_failed";
    },
    getAllAnalytics(year, pageNumber, itemPage) {
      let sum = 0;
      this.$_getPayrollDashboard(`year=${year}${itemPage}${pageNumber}`).then(
        (response) => {
          this.payrunData = response.data;
          this.payrollCategory = response.data.categories;
          this.categoryAnalytics(this.payrollCategory || {});
          this.payrunTableData = response.data.payruns;
          this.metaData = response.data.meta;
          this.loadingTable = false;
          if (this.payrunTableData.length > 0) {
            for (let i = 0; i < this.payrunTableData.length; i++) {
              sum += this.payrunTableData[i].totalAmount;
            }
            this.totalAmount = sum;
          }
          this.loading = false;
        }
      );
    },
    categoryAnalytics(data) {
      this.categoryLegend = [];
      this.categoryTotal = 0;
      let objData;
      this.categoryData = Object.keys(data);
      for (let i = 0; i < this.categoryData.length; i++) {
        switch (this.categoryData[i]) {
          case "regular":
            objData = {
              color: "#35BA83",
              name: "Regular Pay",
              value: `${data.regular}`,
            };
            this.categoryLegend.push(objData);
            break;

          case "offCyle":
            objData = {
              color: "#114FF5",
              name: "Off-Cycle Pay",
              value: `${data.offCyle}`,
            };
            this.categoryLegend.push(objData);
            break;

          case "terminal":
            objData = {
              color: "#878E99",
              name: "Terminal Pay",
              value: `${data.terminal}`,
            };
            this.categoryLegend.push(objData);
            break;

          default:
            break;
        }
        this.categoryTotal = data.regular + data.offCyle + data.terminal;
      }
      this.categoryObject = {
        labels: [...this.categoryData],
        datasets: [
          {
            backgroundColor: ["#35BA83", "#114FF5", "#878E99"],
            offset: 30,
            data: [...Object.values(data)],
          },
        ],
      };
    },

    fillPreviousPayrunChartData() {
      const idArr = [];
      const sumArr = [];
      const labelArr = [];
      let i;
      let sum = 0;
      let objData;

      this.$_getPayrollItems().then((result) => {
        Object.keys(result.data).forEach((id) => {
          const value = result.data[id];
          idArr.push(value.toString());
        });
        const arr = Object.keys(result.data).slice(1);
        this.chartData = idArr.slice(1);
        for (i = 0; i < arr.length; i++) {
          switch (arr[i]) {
            case "approved":
              objData = {
                color: "#321C3B",
                name: "Approved",
                value: `${this.chartData[i]}`,
              };
              labelArr.push(objData);
              break;

            case "disapproved":
              objData = {
                color: "#EA3C53",
                name: "Disapproved",
                value: `${this.chartData[i]}`,
              };
              labelArr.push(objData);
              break;

            case "draft":
              objData = {
                color: "#878E99",
                name: "Drafts",
                value: `${this.chartData[i]}`,
              };
              labelArr.push(objData);
              break;

            case "paid":
              objData = {
                color: "#13B56A",
                name: "Paid",
                value: `${this.chartData[i]}`,
              };
              labelArr.push(objData);
              break;

            case "review":
              objData = {
                color: "#E99323",
                name: "In-review",
                value: `${this.chartData[i]}`,
              };
              labelArr.push(objData);
              break;

            default:
              break;
          }
        }
        this.chartLabels = arr;
        this.chartLegend = labelArr;
        this.chartDataObject = {
          labels: [...this.chartLabels],
          datasets: [
            {
              backgroundColor: [
                "#878E99",
                "#E99323",
                "#13B56A",
                "#321C3B",
                "#EA3C53",
              ],
              offset: 30,
              data: [...this.chartData],
            },
          ],
        };

        this.chartData.map((item) => sumArr.push(parseInt(item, 10)));

        for (i = 0; i < sumArr.length; i++) {
          sum += sumArr[i];
        }

        this.payrunTotal = sum;
      });
    },
    getOverviewYear() {
      this.$_getOverviewYear().then((response) => {
        this.overviewYears = response.data.years;
      });
    },
    handleYearsChange(value) {
      this.loading = true;
      this.year = value;
      this.queryUpdate();
    },
  },

  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },

  mounted() {
    this.getOverviewYear();
    this.viewDashboard();
    this.fillPreviousPayrunChartData();
  },
};
</script>

<style lang="scss" scoped>
.custom-radius {
  border-radius: 7px;
}
.button:focus {
  outline: none;
}
.account {
  border-radius: 5px;
  width: fit-content;
  &_pending {
    background: rgba(242, 201, 76, 0.08);
    color: #e99323;
  }
  &_active {
    background: rgba(19, 181, 106, 0.08);
    color: #27ae60;
  }
  &_failed {
    background: rgba(234, 60, 83, 0.08);
    color: #ea3c53;
  }
}
.font-10{
  font-size: 10px !important;
}
.right-arrow{
  width: 20px !important;
  height: 19px !important;
}
</style>
